/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
//@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
//@import "nav";
//@import "navbar";
//@import "card";
//@import "breadcrumb";
@import "pagination";
@import "badge";
//@import "jumbotron";
@import "alert";
//@import "progress";
@import "media";
@import "list-group";
@import "close";
//@import "toasts";
//@import "modal";
//@import "tooltip";
//@import "popover";
//@import "carousel";
//@import "spinners";
@import "utilities";
//@import "print";



$cat-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$cat-colors: map-merge(("3": darken(desaturate(adjust-hue($primary, 40deg), 20%), 10%),
    "7": desaturate(adjust-hue($primary, 80deg), 20%),
    "426": desaturate(adjust-hue($primary, 120deg), 20%),
    "29": desaturate(adjust-hue($primary, 160deg), 20%),
    "5": desaturate(adjust-hue($primary, 200deg), 20%),
    "1": desaturate(adjust-hue($primary, 240deg), 20%),
    "168": desaturate(adjust-hue($primary, 280deg), 20%),
    "629": desaturate(adjust-hue($primary, 320deg), 20%)),
  $cat-colors);


@mixin mask($bg, $to) {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: linear-gradient(to $to, rgba($bg, 1) 0%, rgba($bg, 1) 50%, rgba($bg, 0) 50.1%, rgba($bg, 0) 70%, rgba($bg, 1) 70.1%, rgba($bg, 1) 80%, rgba($bg, 0) 80.1%, rgba($bg, 0) 85%, rgba($bg, 1) 85.1%, rgba($bg, 1) 90%, rgba($bg, 0) 90.1%, rgba($bg, 0) 95%, rgba($bg, 1) 95.1%, rgba($bg, 1) 100%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
}

@mixin bgc($from, $to) {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, $from) 0%, rgba(0, 0, 0, $from) 50%, rgba(0, 0, 0, $to) 100%);
  z-index: 1;
}

$loading_speed: 1.5s;
$loading_delay: 0.4s;



.text-light a {
  color: $light;
}

.text-dark a {
  color: $dark;
}


a {
  position: relative;
  transition: 0.4s;
  display: inline-block;
  z-index: 1;

  &:not(.btn):after {
    transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    content: '';
    display: block;
    position: absolute;
    left: -5px;
    top: 0;
    width: 0px;
    height: 100%;
    z-index: -1;
    background: lighten($primary, 15%);
  }

  &.linkimg:after {
    content: none;
  }

  &.linkimg:hover {
    opacity: 0.8;
  }

  &:hover {
    color: #fff !important;

    &:after {
      box-sizing: content-box;
      width: 100%;
      padding-right: 10px;
    }
  }

  .recent_s_title &:after {
    content: none !important;
  }

  &.btn:hover {
    color: #fff !important;
  }
}




.en {
  font-family: Arial, Helvetica, sans-serif;
  color: $secondary;

  body>footer & {
    color: lighten($secondary, 10%);
  }

  &.date {
    color: inherit;
  }
}

body {
  word-break: break-all;


  min-height: 100vh;

  >.container-fluid {
    overflow: hidden;

    padding-right: 50px;

    @include media-breakpoint-down(xs) {
      padding-right: 0;
    }

    padding-left: 0;
    position: relative;
  }

  footer.container-fluid:after {
    content: none !important;
  }
}



.navigation {
  @extend .h-100;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.4);
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);

  @include media-breakpoint-down(xs) {
    background: none;
    box-shadow: none;
    height: auto !important;
    right: -50px;
  }

  width: 50px;
  z-index: 1030;
  transition: 0.8s;

  .navigation-main {
    @extend .h-100;
    @extend .ml-auto;
    @extend .d-flex;
    @extend .align-items-start;
    @extend .align-items-sm-center;
    transition: 0.4s width cubic-bezier(0.23, 1, 0.32, 1);

    @include media-breakpoint-down(xs) {
      transition: 0.8s width cubic-bezier(0.23, 1, 0.32, 1);
    }

    background: #fff;
    width: 50px;
  }

  .navigation-list {
    @extend .w-100;
    @extend .py-4;
    @extend .pr-2;
    @extend .pr-sm-4;
    @extend .h-100;
    padding-left: 10px;
    overflow: auto;
    transition: 0.4s opacity cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
    transition: 0.2s 0.6s;
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
      height: 100vh !important;
    }

    a {
      white-space: nowrap;
      color: #222;

      &:hover {
        color: $primary;
      }
    }

    li {
      margin-bottom: 20px;

      li {
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 0.8rem;
        display: inline-block;

        .badge {
          display: none;
        }
      }
    }
  }

  .navigation-archive {
    @extend .mx-0;

    li {
      display: inline-block;
      white-space: nowrap;
      margin-right: 20px;
    }

  }

  .navigation-category>li>a {
    @extend .en;
    font-weight: 400;
    color: $dark;

    &:hover {
      color: #fff;
    }
  }

  .badge {
    color: #fff;
    border-radius: 2rem;
    position: relative;
    top: -1px;
    background: #bbb;
    transition: .4s;
    margin-left: 10px;
  }

  a:hover .badge {
    background: $primary;
  }


  .navigation-button {
    @extend .mt-2;
    @extend .mt-sm-0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    width: 50px;
    height: 50px;
    min-width: 50px;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      left: -50px;
      position: relative;
      background: #fff;
    }
  }

  .ps__rail-y {
    display: none !important;
  }

  &.open {
    @include media-breakpoint-down(xs) {
      box-shadow: none;
    }

    .ps__rail-y {
      opacity: 0 !important;
    }

    max-width: none;
    width: 100%;

    @include media-breakpoint-down(xs) {
      right: 0;

    }

    .navigation-main {
      width: 50%;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    .navigation-list {
      opacity: 1;
      display: block;

      li {
        opacity: 1;
      }
    }

    .navigation-button {
      left: 0;

      .d-inline {
        display: none !important;
      }

      .d-none {
        display: inline !important;
      }
    }
  }
}

.bgc {
  background-size: cover;
  background-position: center center;
  color: #fff;
  position: relative;

  &:after {
    @include bgc(0, 0.3);
  }

  .container-single &:after {
    @include bgc(0.4, 0.6);
  }

  &.recent_l:after {
    @include bgc(0.6, 0.7);
    opacity: 1;
  }

  a {
    color: #fff;
  }

  >* {
    position: relative;
    z-index: 100;
  }
}

.recent_l {
  @extend .h-100;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .px-2;
  @extend .px-sm-4;
  @extend .text-light;

  @include media-breakpoint-down(xs) {
    min-height: 60vh !important;
    padding-top: 100px !important;
    padding-bottom: 50px !important;
  }




  >* {
    position: relative;
    z-index: 10;
  }



  transform: translateX(100px);

  .container-fluid:nth-child(even) & {
    transform: translateX(-100px);
  }

  opacity: 0;

  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1),
  opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1);


  body.loading-end &.inview {
    opacity: 1;
    transform: translateX(0);
  }
}



.recent_s {
  @extend .d-flex;
  @extend .flex-column;
  @extend .h-50;

  @include media-breakpoint-down(xs) {
    height: auto !important;
  }


  @include media-breakpoint-down(xs) {
    border-left: none !important;
  }

  overflow: hidden;

  .recent_s_title {
    @extend .p-1;
    @extend .d-flex;
    position: relative;

    >a {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      @include bgc(0.2, 0.4);
      opacity: 1;
      transition: 0.4s;
    }

    &:hover:after {
      opacity: 0;
    }


  }

  &:nth-child(even) {
    @extend .justify-content-end;

    .h-60 {
      @extend .align-items-end;
      @extend .justify-content-end;
    }
  }

  .recent_s_title>a {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .recent_s_text {
    @extend .p-2;
    @extend .align-items-start;
    @extend .justify-content-center;
    @extend .d-flex;
    @extend .flex-column;
    z-index: 100;

    .arrow {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
  }

  &:nth-child(even) .recent_s_text {
    @extend .order-first;

    .arrow {
      border-width: 10px 10px 0 10px;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
      border-bottom-color: transparent !important;
      right: 20px;
      bottom: -10px;
    }
  }

  &:nth-child(odd) .recent_s_text {
    @extend .order-last;

    .arrow {
      border-width: 0 10px 10px 10px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
      left: 20px;
      top: -10px;
    }
  }

  .recent_s_title,
  .recent_s_text {
    @extend .h-50;
    min-height: 175px;

    @include media-breakpoint-down(xs) {
      height: auto !important;
    }
  }

  transform: translateY(50px);

  &:nth-child(even) {
    transform: translateY(-50px);
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      transition-delay: #{($i - 1) * 0.15 + 0.3}s;
    }
  }

  opacity: 0;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1),
  opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1);

  body.loading-end .row-end.row.inview>& {
    opacity: 1;
    transform: translateY(0);
  }


}

.cat {
  margin-left: -10px;

  li {
    white-space: nowrap;
    font-size: 0.8rem;
    position: relative;
    padding: 0 10px;

    &:not(:last-child):after {
      content: '';
      display: block;
      width: 2px;
      height: 1rem;
      background: #fff;
      position: absolute;
      right: -4px;
      top: 4px;
      transform: skew(-30deg);
    }
  }
}

header {
  .cat {
    li {
      opacity: 0;
      transform: translateX(-30px);
      transition: 0.4s;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: #{($i - 1) * 0.2 + 1.1}s;
        }
      }
    }

  }

  body.loading-end &.inview .cat li {
    opacity: 1;
    transform: translateX(0);
  }

}

.title {
  max-width: 5rem;
  height: 0px !important;
  overflow: hidden;

  @include media-breakpoint-down(xs) {
    overflow: visible;
    height: auto !important;
    width: 0;
    max-width: none;
    order: -1 !important;
  }

  transition: 1.4s $loading_speed - 1;

  .loading-end .inview & {
    height: 100% !important;
    background: #fff;

    @include media-breakpoint-down(xs) {
      height: auto !important;
      width: 100%;
    }
  }

  .container-fluid:nth-child(even) & {
    @extend .order-first;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  h2 {
    width: 100vh;
    @extend .p-2;
    @extend .pr-sm-4;
    @extend .py-sm-4;
    @extend .pl-sm-2;
    @extend .mb-0;
    @extend .justify-content-between;
    @extend .align-items-center;
    font-family: "Playfair Display", "Times New Roman", sans-serif;
    position: absolute;
    left: 7rem;
    top: 0;
    transform: rotate(90deg);
    transform-origin: left top;
    color: $dark;
    line-height: 1;
    font-weight: 300;
    font-size: 2.5rem;
    white-space: nowrap;

    @include media-breakpoint-down(xs) {
      width: auto;
      position: static;
      transform: none;
      font-size: 1.5rem;
      white-space: normal;
    }

    .small {
      font-size: 1rem;
      @extend .align-self-end;
    }

    a {
      color: $dark;

      &:hover {
        color: #fff !important;
      }
    }
  }
}

.container-single {
  @extend .pt-4;

  .text_single {

    .ads:not(:last-child),
    blockquote:not(:last-child),
    .module:not(:last-child),
    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    p:last-child {
      margin-bottom: 0;
    }

    h3,
    h4,
    h5 {
      margin-top: 4rem;

      &+h3,
      &+h4,
      &+h5 {
        margin-top: 2rem;
      }
    }



    blockquote {
      background: $gray-200;
      padding: 1rem;
    }

    .ads {
      margin-right: -1rem;
      margin-left: -1rem;
    }



  }

  >.bgc {
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }


}

.text_single {
  h3 {
    color: $secondary;
    font-size: 1.5rem;
    margin: 2rem 0;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          $secondary 0%,
          $secondary 20%,
          lighten($secondary, 25%) 20.1%,
          lighten($secondary, 25%) 100%);
    }

    &:before {
      margin-bottom: 20px;
    }

    &:after {
      width: 100%;
      height: 1px;
      margin-top: 20px;
      background: #c6cdd8;
    }
  }

  h4 {
    color: $secondary;
    font-size: 1.2rem;
    border-left: 4px solid $secondary;
    padding-left: 20px;
  }
}

.date {
  @extend .en;
}

p.date {
  margin-bottom: 0;
  opacity: 0.7;
}

dl.date {
  @extend .mb-0;

  dt {
    font-weight: 300;
    font-size: 0.8rem;

    @include media-breakpoint-down(xs) {
      font-size: 1rem;
      display: inline !important;
    }

    &:after {
      @include media-breakpoint-down(xs) {
        content: '/'
      }
    }
  }

  dd {
    @extend .mb-0;

    @include media-breakpoint-down(xs) {
      display: inline !important;
    }
  }
}


[class^="col"] {
  @extend .p-0;
}

[class^="row"] {
  @extend .m-0;
}

.vh-100 {
  min-height: 700px;

  @include media-breakpoint-down(xs) {
    min-height: 0;
    height: auto !important;
  }
}

ul,
dl {
  @extend .list-unstyled;
  @extend .mb-0;
}


.ps__rail-y {
  opacity: 1 !important;
  right: 10px !important;
}

.social {
  @extend .pb-4;
  @extend .pb-sm-0;
  @extend .px-4;
  @extend .py-sm-0;

  li {
    margin-top: 30px;

    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }

    .container-single & {
      transform: translateX(-30px);
      opacity: 0;
      transition: 0.4s;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.1 * $i + 0.4}s
        }
      }
    }

    .row-end & {
      transform: translateX(0);
      opacity: 1;
    }
  }

  i {
    font-size: 1.5rem;
    transition: 0.4s;
  }

  a {
    color: $light;

    &:hover:after {
      content: none;
    }
  }

  .navigation & {
    @extend .px-0;
    @extend .pt-0;

    li {
      margin-top: 0;
    }

    a {
      color: $dark;
    }
  }

  .icon-twitter a:hover i {
    color: #1D9DEA;
  }

  .icon-line a:hover i {
    color: #16B400;
  }

  .icon-facebook a:hover i {
    color: #38569A;
  }

  .icon-pocket a:hover i {
    color: #DF3251;
  }

  .icon-feed a:hover i {
    color: #F86D01;
  }

  .icon-hatena a:hover i {
    color: #14A4DE;
  }

  .icon-instagram a:hover i {
    color: #D64594;
  }

}

body {
  >header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    @extend .text-light;

    .logo {
      margin-bottom: 0;
      overflow: hidden;

      a {
        display: block;
        text-indent: -200em;
        overflow: hidden;
        width: 205px;
        height: 34.3px;
        background: url(../img/logo.svg);
        background-size: cover;
        position: relative;

        &:hover:after {
          content: none;
        }



      }


    }

    a {
      color: #fff;
    }
  }

  >footer {
    @extend .bg-dark;
    color: #fff;

    p {
      font-size: 0.8rem;
    }

    .copy {
      @extend .text-center;

      p {
        font-size: 0.8rem;
        color: $gray-500;
      }
    }
  }
}

.box {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: left center;
    z-index: 100;
  }

  >* {
    opacity: 0;
    transition: 0.01s opacity 0.55s;
  }

}

.box_blue {

  &:before {
    background: $secondary;
  }
}

body.loading-end .box.inview {
  &:before {
    animation: box 1.1s;
  }

  >* {
    opacity: 1;

  }
}

@keyframes box {
  0% {
    transform: scaleX(0);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: left center;
  }

  50% {
    transform: scaleX(1);
    transform-origin: left center;
  }

  53% {
    transform: scaleX(1);
    transform-origin: right center;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  100% {
    transform: scaleX(0);
    transform-origin: right center;
  }
}




.title_single {
  @extend .pt-5;
  @extend .pb-3;
  @extend .pb-sm-5;

  @extend .px-2;
  @extend .pl-sm-5;

  @extend .text-white;

  .title_single_date {
    @extend .pr-3;
    @extend .border-right;
    @extend .d-flex;
    @extend .align-items-center;

    @include media-breakpoint-down(xs) {
      border-right: none !important;
    }
  }
}

.text_single {
  @extend .bg-white;
  @extend .p-2;
  @extend .p-sm-3;
}

.comment-reply-title {
  @extend .en;
}

.comment-respond {
  p {
    @extend .row;
    @extend .mx-0;

    label {
      @extend .col-12;
      @extend .col-sm-4;
      @extend .mx-0;
      @extend .px-0;
    }

    input:not([type="submit"]),
    textarea {
      @extend .col-12;
      @extend .col-sm-8;
      @extend .form-control;
    }
  }

  .required {
    @extend .mx-1;
    color: $red;
  }

  input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
  }

  .form-submit {
    @extend .justify-content-end;
  }
}

.row-comment+.row-comment {
  margin-top: 2rem !important;
  padding-top: 2rem;
  border-top: 1px solid #ddd;
}


.container-archive {
  .recent_l {
    /*
    @extend .col-12;
    @extend .col-sm-6;
    */
    @extend .vh-100;

    @include media-breakpoint-down(xs) {
      height: 50vh !important;
    }
  }

  >.row>article,
  div {
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        order: #{$i};
      }

      @if $i % 4==3 and $i !=1 {
        &:nth-child(#{$i}) {
          order: #{$i + 1};
        }

        &:nth-child(#{$i})+* {
          order: #{$i - 1};
        }
      }
    }

  }
}

.related {
  >h3 {
    position: relative;
    z-index: 100;
  }

  >.row {

    @include media-breakpoint-down(md) {
      height: auto !important;
    }

  }

  &.related-half>.row {
    height: 50vh !important;
    min-height: 350px !important;

    @include media-breakpoint-down(xs) {
      height: auto !important;
    }
  }

  .recent_s {
    @extend .col-6;
    @extend .col-md-4;
    @extend .col-xl-3;
    height: auto !important;
  }


  .recent_s:nth-last-child(1),
  .recent_s:nth-last-child(2) {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }

  }

}

.pagination {
  background: #eee;
  @extend .en;
  @extend .justify-content-center;
  @extend .px-0;
  @extend .mx-0;
  font-weight: 700;
  border-top: 1px solid #ddd;

  >* {
    @extend .col;
    @extend .py-2;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    border-left: 1px solid #ddd;

    &:last-child {
      border-right: 1px solid #ddd;
    }
  }

  a:hover {
    color: #fff;
    background: $primary;
  }

  @include media-breakpoint-down(xs) {
    span.page_num {
      display: none;
    }
  }
}


.beforeafter .col-12 {
  @include media-breakpoint-down(xs) {
    &.border-right {
      border-right: none !important;
    }
  }

  >a {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;

    i+span,
    span+i {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

}


.kaerebalink-box {
  font-size: 1rem !important;
  border: 4px solid #ddd;
  padding: 10px !important;
  box-sizing: border-box;
  margin-bottom: 3rem;
  max-width: 800px;
  @extend .row;
  @extend .mx-0;

  .kaerebalink-image {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .px-0;
    @extend .m-0;
    @extend .mb-2;
    @extend .mr-0;
    @extend .mr-sm-2;
    @extend .mb-sm-0;
    float: none !important;
    max-width: 200px;

    @include media-breakpoint-down(xs) {
      text-align: center;
      max-width: none;
    }

    img {
      @extend .img-fluid;
    }
  }

  .kaerebalink-info {
    @extend .col-12;
    @extend .col-sm;
    @extend .px-0;
    @extend .d-flex;
    @extend .flex-column;
    padding-left: 10px;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }

    p {
      display: none;
    }
  }

  .kaerebalink-name {
    line-height: 1.5rem !important;

    a:first-child {
      font-weight: 700;
    }
  }

  .kaerebalink-detail {
    font-size: 0.85rem;
  }

  .kaerebalink-link1 {
    @extend .row;
    @extend .mx-0;
    @extend .mt-auto;

    >div {
      @extend .col;
      @extend .px-0;
      @extend .px-sm-1;
      background: none !important;
    }

    a {
      display: block;
      background: #ddd;
      color: #fff;
      padding: 10px;
      text-align: center;

      &:after {
        content: none;
      }
    }

    .shoplinkamazon a {
      background: #F3A847;
      color: #232F3E;

      &:hover {
        color: #232F3E !important;
        background: lighten(#F3A847, 10%);
      }
    }

    .shoplinkrakuten a {
      background: #BF0600;

      &:hover {
        background: lighten(#BF0600, 10%);
      }
    }

  }
}

.footer-contact {
  font-size: 0.85rem;

  input[type="text"],
  input[type="email"],
  textarea {
    @extend .form-control;
    font-size: 0.85rem;
  }

  input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
  }

  textarea {
    height: 200px;
  }
}

.wpcf7-validation-errors {
  background: $danger;
  color: #fff;
  padding: 10px;
  margin-top: 3rem;
}

.wpcf7-not-valid-tip {
  color: $warning;
}

.screen-reader-response {
  display: none;
}


.notfound {
  height: 50vh !important;
  padding-top: 115px !important;
  @extend .px-2;
  @extend .bg-dark;
  @extend .text-light;

}


.fa-hatena {
  &:before {
    content: "B!";
    font-family: Verdana;
    font-weight: 700;
  }
}

.loading {
  @include mask(desaturate($primary, 10%), right);
  z-index: 100000;
  position: fixed;
  text-align: center;

  body.loaded & {
    animation: loading_out $loading_speed $loading_delay forwards;
  }

  body.moving & {
    @include mask(desaturate($primary, 10%), left);
    position: fixed;
    z-index: 100000;
    left: auto;
    right: 0;
    width: 100%;
    background-size: 0 100%;
    background-position: right top;
    animation: loading_in $loading_speed * 0.6 0s forwards;
  }

  >div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-100%, -100%);
  }

  .dot-pulse {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px #fff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
    transition: $loading_delay opacity;

    body.loaded & {
      opacity: 0;
    }


  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
  }

  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #fff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #fff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
}

@keyframes loading_out {
  0% {
    background-size: 200% 100%;
  }

  99.9% {
    background-size: 0 100%;
  }

  100% {
    background-size: 0 100%;
    width: 0;
  }
}



@keyframes loading_in {
  0% {
    width: 0;
    background-size: 0 100%;
  }

  0.1% {
    width: 100%;
    background-size: 0 100%;
  }

  100% {
    width: 100%;
    background-size: 200% 100%;
  }
}


h3,
h4,
h5 {
  width: 100%;
}

@media all and (-ms-high-contrast: none) {
  .img-fluid {
    width: 100%;
  }
}

#toc_container .toc_list {
  li {
    line-height: 1.5;

    &+li {
      margin-top: 0.5rem;

    }

  }
}


kbd {
  margin: auto 0.25rem;
  border-radius: 0.25rem;

}

#toc_container li a:hover {
  text-decoration: none;
}


#swipebox-close:after {
  content: none;
}
